var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.$waiting.is('loading')),expression:"$waiting.is('loading')"}],staticClass:"flex print:block print:pl-2"},[_c('div',{staticClass:"relative hidden print:block text-center mb-5 pb-4 border-b w-full"},[_c('p',{staticClass:"absolute top-0 right-0 text-xs"},[_vm._v("utskriven "+_vm._s(_vm.todayDate))]),_c('p',[_vm._v(_vm._s(_vm.tableData.heading1))]),_c('p',[_vm._v(_vm._s(_vm.tableData.heading2))])]),_c('div',{staticClass:"card-tail sm:w-1/2 print:sm:w-full"},[_c('table',{staticClass:"w-full"},[_vm._m(0),_vm._l((_vm.tableData.row),function(item){return _c('tr',{key:item.account,class:{ 'hover:bg-gray-100': item.type === 4 },on:{"click":function($event){return _vm.rowClicked(item)}}},[_c('td',[_c('p',{staticClass:"w-14 text-sm print:text-xs"},[_vm._v(_vm._s(item.account))])]),_c('td',[_c('p',{staticClass:"flex-1 print:text-xs",class:{
                            'font-bold': item.type === 1,
                            'font-semibold': item.type === 5 || item.type === 2,
                            'text-sm': item.type === 4,
                            'mb-4': item.type === 3,
                        }},[_vm._v(" "+_vm._s(item.name)+" ")])]),_c('td',{attrs:{"width":"160"}},[_c('p',{staticClass:"text-sm print:text-xs text-right",class:{
                            'font-semibold': item.type === 5,
                        }},[_vm._v(" "+_vm._s(_vm._f("swedishNumberFormat")(item.opening))+" ")])]),_c('td',{attrs:{"width":"160"}},[_c('p',{staticClass:"text-sm print:text-xs text-right",class:{
                            'font-semibold': item.type === 5,
                        }},[_vm._v(" "+_vm._s(_vm._f("swedishNumberFormat")(item.period))+" ")])]),_c('td',{attrs:{"width":"160"}},[_c('p',{staticClass:"text-sm print:text-xs text-right",class:{
                            'font-semibold': item.type === 5,
                        }},[_vm._v(" "+_vm._s(_vm._f("swedishNumberFormat")(item.closing))+" ")])])])})],2)]),_c('div',{staticClass:"w-1/2 ml-3 print:hidden"},[_c('AccountDetails',{ref:"accountDetailsView",attrs:{"client-id":_vm.clientId,"account-number":_vm.selectedAccount,"selected-date":_vm.selectedDate}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td'),_c('td'),_c('td',{staticClass:"text-sm font-semibold",attrs:{"align":"right"}},[_vm._v("Ingående balans")]),_c('td',{staticClass:"text-sm font-semibold",attrs:{"align":"right"}},[_vm._v("Period")]),_c('td',{staticClass:"text-sm font-semibold",attrs:{"align":"right"}},[_vm._v("Utgående balans")])])}]

export { render, staticRenderFns }