<template>
    <div class="flex print:block print:pl-2" v-loading="$waiting.is('loading')">
        <div class="relative hidden print:block text-center mb-5 pb-4 border-b w-full">
            <p class="absolute top-0 right-0 text-xs">utskriven {{ todayDate }}</p>
            <p>{{ tableData.heading1 }}</p>
            <p>{{ tableData.heading2 }}</p>
        </div>
        <div class="card-tail sm:w-1/2 print:sm:w-full">
            <table class="w-full">
                <tr>
                    <td />
                    <td />
                    <td align="right" class="text-sm font-semibold">Ingående balans</td>
                    <td align="right" class="text-sm font-semibold">Period</td>
                    <td align="right" class="text-sm font-semibold">Utgående balans</td>
                </tr>
                <tr v-for="item in tableData.row" :key="item.account" :class="{ 'hover:bg-gray-100': item.type === 4 }" @click="rowClicked(item)">
                    <td>
                        <p class="w-14 text-sm print:text-xs">{{ item.account }}</p>
                    </td>
                    <td>
                        <p
                            class="flex-1 print:text-xs"
                            :class="{
                                'font-bold': item.type === 1,
                                'font-semibold': item.type === 5 || item.type === 2,
                                'text-sm': item.type === 4,
                                'mb-4': item.type === 3,
                            }"
                        >
                            {{ item.name }}
                        </p>
                    </td>
                    <td width="160">
                        <p
                            class="text-sm print:text-xs text-right"
                            :class="{
                                'font-semibold': item.type === 5,
                            }"
                        >
                            {{ item.opening | swedishNumberFormat }}
                        </p>
                    </td>
                    <td width="160">
                        <p
                            class="text-sm print:text-xs text-right"
                            :class="{
                                'font-semibold': item.type === 5,
                            }"
                        >
                            {{ item.period | swedishNumberFormat }}
                        </p>
                    </td>
                    <td width="160">
                        <p
                            class="text-sm print:text-xs text-right"
                            :class="{
                                'font-semibold': item.type === 5,
                            }"
                        >
                            {{ item.closing | swedishNumberFormat }}
                        </p>
                    </td>
                </tr>
            </table>
        </div>
        <div class="w-1/2 ml-3 print:hidden">
            <AccountDetails ref="accountDetailsView" :client-id="clientId" :account-number="selectedAccount" :selected-date="selectedDate" />
        </div>
    </div>
</template>
<script>
import moment from "moment";
import Api from "../lopande.api";

export default {
    data() {
        return {
            tableData: [],
            selectedAccount: null,
        };
    },

    props: {
        clientId: {
            type: String,
            default: "",
        },
        selectedDate: {
            type: Array,
            default: () => [],
        },
    },

    components: {
        AccountDetails: () => import("./LopandeBalansrakningAccountDetails"),
    },

    watch: {
        selectedDate: function() {
            this.getAll();
        },
    },

    computed: {
        todayDate() {
            return moment().format("YYYY-MM-DD");
        },
    },

    created() {
        this.getAll();
    },

    methods: {
        async getAll() {
            if (!this.selectedDate.length) return;

            this.$waiting.start("loading");
            try {
                let data = await Api.getResultatbalances({ clientId: this.clientId, startDate: this.selectedDate[0], endDate: this.selectedDate[1] });
                this.tableData = Object.freeze(data);
                this.$refs.accountDetailsView.resetState();
                this.selectedAccount = null;
            } finally {
                this.$waiting.end("loading");
            }
        },

        formatDate(date) {
            return moment(date).format("YYYY.MM.DD");
        },

        rowClicked(item) {
            if (!item.account) return;
            this.selectedAccount = item.account;
        },
    },
};
</script>
